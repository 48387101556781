<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>线下企业</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <!-- <el-button size="small" @click="gotoAdd">+添加信息</el-button> -->
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div class="search">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchfrom" class="demo-form-inline">
                        <el-form-item label="企业名">
                            <el-input v-model="searchfrom.enterpriseName" clearable placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="联系人">
                            <el-input v-model="searchfrom.name" clearable placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="searchfrom.phone" clearable placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="日期">
                            <el-date-picker
                            v-model="searchfrom.needTime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit" class="mybotton">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright">
                    <el-link href="/img/outlineqy.xlsx" target="_blank" type="primary" style="margin-right:15px;">模板下载</el-link>
                    <el-upload
                    class="upload-demo"
                    action="/api/offline/enterprise/importFile"
                    :limit="1"
                    ref='upload'
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    :on-success="onSuccess">
                        <el-button  type="primary" icon="el-icon-upload2" style="margin-right:8px;"  :loading="uploading">导入信息</el-button>
                    </el-upload>
                    <el-button  type="primary" class="mybotton" icon="el-icon-circle-plus-outline" @click="openadd">添加信息</el-button>
                </div>
            </div>
            <div class="list">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="enterpriseName"
                    label="企业名称">
                    </el-table-column>
                    <el-table-column
                    prop="demand"
                    label="岗位需求">
                    </el-table-column>
                    <el-table-column
                    prop="demandNumber"
                    label="需求人数">
                    </el-table-column>
                    <el-table-column
                    prop="needTime"
                    label="时间">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="联系人">
                    </el-table-column>
                    
                    <el-table-column
                    prop="phone"
                    label="联系电话">
                    </el-table-column>
                    
                    <el-table-column
                    prop="address"
                    width="200"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"  @click="openedit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="delbooth(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up :title="status==1?'添加':'编辑'" width="35%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="企业名称">
                        <el-input v-model="form.enterpriseName"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位需求">
                        <el-input v-model="form.demand"></el-input>
                    </el-form-item>
                    <el-form-item label="需求人数">
                        <el-input v-model="form.demandNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="时间">
                        <el-date-picker
                        v-model="form.needTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="联系人">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            headers:{
                AuthorToken:JSON.parse(window.localStorage.getItem('token'))
            },
            searchfrom:{
                enterpriseName:null,
                needTime:null,
                phone:null,
                name:null,
                },
            form:{
                id:null,
                enterpriseName:null,
                demand:null,
                demandNumber:null,
                needTime:null,
                phone:null,
                name:null,
            },
            list:[],
            total:0,
            status:1,
            pageNo:1,
            pageSize:20,
            loading:false,
            showpop:false,
            s:null,
            currdate:null,
            uploading:false,
        }
    },
    created(){
        var date = new Date()
        var month = parseInt(date.getMonth() + 1)
        var day = date.getDate()
        var day2 = date.getDate()-1
        if (month < 10)  month = '0' + month
        if (day < 10)  day = '0' + day
        if (day2 < 10)  day2 = '0' + day2
        this.currdate = date.getFullYear() + '-' + month + '-' + day
        this.form.createTime=this.currdate
        this.s=this.$route.query.s
        // console.log(this.s)
        if(this.s!=null && this.s!=undefined && this.s!=''){
            this.searchfrom.time=date.getFullYear() + '-' + month + '-' + day2
        }

        this.getList()
    },
    methods:{
        onSuccess(response, file, fileList){
            console.log(222)
            this.uploading=false
            this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
            console.log(response)
            if(response.code==200){
                this.$message.success("导入成功！")
                this.getList()
            }
        },
        beforeUpload(file){
            console.log(111)
            this.uploading=true
            return true
        },
        getList(){
            this.loading=true
            _api.get(serverurl.enterprise,{page:this.pageNo,size:this.pageSize,...this.searchfrom}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        delbooth(row){

            this.$confirm('确定要删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                _api.delete(serverurl.enterprise,row.id).then(res=>{
                console.log(res)
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        this.getList()
                    }
                })
            }).catch(() => {
                    
            });

            
        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.status=1
            this.showpop=true
        },
        getconfirm(){
            let apitype=this.status==1?'post':'put'
            _api[apitype](serverurl.enterprise,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        getclose(){
            this.showpop=false
            this.status=1
            this.form={
                id:null,
                enterpriseName:null,
                demand:null,
                demandNumber:null,
                needTime:null,
                phone:null,
                name:null,
            }
            // this.form.createTime=this.currdate
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>
<style scoped>
.searchright{ display: flex; align-items: center;}
</style>
